.service-screen {
    padding: 80px 0px 0;
    .title {
        text-align: center;
        letter-spacing: 2px;
        font-size: 65px;
        text-shadow: 2px 2px 2px white;
        margin-bottom: 80px;

        @media screen and (max-width:1024px) {
            font-size: 33px;
            letter-spacing: normal;
            margin-top: 50px;
            margin-bottom: 40px;
        }
    }
}