.tile-component {
    padding: 80px 0;
    background-color: aliceblue;
    background-size: cover;
    background-position: center;
    position: relative;
    
    &::before {
        content: '';
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        background: rgba(rgb(0, 0, 0), .5);
        transition: all linear 200ms;
    }

    * {
        position: relative;
        z-index: 200;
    }

    .container {
        padding: 0 100px;

        @media screen and (max-width:1024px) {
            padding: 0 20px;
        }
    }
    .title {
        font-size: 50px;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-shadow: 2px 2px 3px black;
        color: white;


        @media screen and (max-width:1024px) {
            text-align: center;
            font-size: 37px;

        }
    }
    .headline {
        font-weight: bold;
        width: 50%;
        font-size: 30px;
        letter-spacing: 1px;
        line-height: 40px;
        color: white;
        text-align: justify;

        @media screen and (max-width:1024px) {
            text-align: justify;
            width: 100%;
            line-height: 25px;
            letter-spacing: 2px;
            font-size: 20px;
        }
    }
    .cards {
        display: flex;
        gap: 50px;

        @media screen and (max-width:1024px) {
            flex-direction: column;
            gap: 0px;
        }

        &:last-of-type {
            .card:last-of-type {
                .card-image:last-of-type {
                    object-fit: cover;
                    border-radius: 20px;
                }
            } 
        }

        .card {
            display: flex;
            flex: 1 1 50%;
            border: 1px solid rgb(182, 208, 230);
            align-items: center;
            border-radius: 20px;
            gap: 36px;
            padding: 30px;
            background-color: white;
            margin: 20px 0;
            flex-direction: column;
            
            @media screen and (max-width:1024px) {
                flex-direction: column;
            }
         
            &:last-child {
                .card-title {
                    font-size: 23px;
                }
            }

            .card-image {
                height: 250px;
                width: 100%;
                object-fit: cover;
                border-radius: 20px;
            }

            .card-title {
                font-size: 25px;
            }

            .card-description {
                font-size: 17px;
                text-align: justify;
            }
        }
       
    }

    
}
