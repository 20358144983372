.main-navigation {
    background-color: black;
    display: flex ;
    padding: 0px;
    position: fixed;
    width: 100%;
    z-index: 999;
    flex: 1 1 100%;
    top: 0;
}

.main-navigation .main-navigation-img {
    justify-content: center;
    align-items: center;
    display: flex;
    object-fit: cover;
    padding: 0 30px;
}

.main-navigation .main-navigation-links {
    list-style-type: none;
    display: flex ;
    margin: 0;
    padding: 0 0 0 32px;
    width: 100%;
    align-items: center;
}

.main-navigation .main-navigation-links li a {
    padding: 8px 10px;
    display: inline-flex;
    text-decoration: none;
    color:white;
    font-size: 14px;
    font-weight: bold;
    align-items: center;
    letter-spacing: 1px;
    transition: all linear 200ms;
}

.main-navigation .main-navigation-links li a:hover {
    color: white;
    transform: scale(1.1);
    transition: all linear 150ms;
    cursor: pointer;
}

.main-navigation .mobile-toggler {
    position: absolute;
    right: 10px;
    top: 28px;
    background: transparent;
    border: 0;
    display: none;
    cursor: pointer;
    transition: all linear 200ms;
}

.main-navigation .mobile-toggler:hover {
    transform: scale(1.1);
}


.main-navigation .mobile-toggler span {
    width: 30px;
    height: 5px;
    background: white;
    display: block;
    margin-bottom: 4px;
}

@media screen and (max-width: 1024px) {
    .main-navigation .mobile-toggler {
        display: block;
    }  
    .main-navigation .main-navigation-links {
        flex-direction: column;
        padding: 0;
    }

    .main-navigation {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .main-navigation-img {
        padding-top: 6px;
    }
}

@media screen and (min-width:1024px) {
    .main-navigation .main-navigation-links {
        display: flex !important;
    }
}