.flip-side {
    display: flex;
}

.flip-side.reverse {
    flex-direction: row-reverse;
}


.flip-side .image {
    width: 50%;
    background-size: cover;
    min-height: 100vh;
    background-position: bottom;
}

.flip-side .content {
    width: 50%;
    padding: 40px 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    letter-spacing: 1.5px;
}

.flip-side .content .title {
    font-size: 50px;
    border-left: 7px solid red;
    padding-left: 20px;
    letter-spacing: 2px;
    margin-bottom: 20px;
}

.flip-side .content .description {
    font-size: 20px;
    line-height: 30px;
    text-align: justify;
}

.flip-side .content .button {
    margin: 32px auto 0;
    margin-top: 32px;
    text-decoration: none;
}

.flip-side .content a {
    text-decoration: none;
}

@media screen and (max-width:1024px) {
    .flip-side {
        display: flex;
        flex-direction: column;
        flex-direction: column-reverse;
    }

    .flip-side:last-child .image {
        background-position: 600px;     
    }
    
    .flip-side.reverse {
        flex-direction: column-reverse;
    }

    .flip-side .content {
        padding: 40px 25px;
    }

    .flip-side .image,
    .flip-side .content {
        width: auto;
        flex: 1;
    }

    .flip-side .content .title {
        font-size: 37px;
        padding-left: 12px;
    }
}