.content-circles {
    padding:0 0 80px;
    min-height: 60vh;
    background: linear-gradient(0deg, rgb(48, 54 , 66) 0%, rgba(255,255,255,1) 80%);


    .container {
        padding: 0 80px;
        display: flex;
        gap: 50px;
        justify-content: center;
        flex-direction: column;

        @media screen and (max-width:1024px) {
            flex-direction: column;
            padding: 0 16px;
        }
    }
    .feature {
        flex: 1 1 33.3%;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        padding: 30px;
        transition: all linear 200ms;
        cursor: pointer;
        border-radius: 20px;
        opacity: 0;
        animation-name: cardsFadeIn;
        animation-duration: 500ms;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;

        @keyframes cardsFadeIn {
            0% {
                transform: translateY(500px);
                opacity: 0;
            }
            100% {
                transform: translateY(0);
                opacity: 1;
            }
        }

        &:hover {
            transform: scale(1.2);
            box-shadow: 1px 1px 20px 1px  #363636;
            background: white;

            @media screen and (max-width:1024px) {
                flex-direction: column;
                padding: 30px 50px;

            }

        }

        

        &:hover .description {
            transform: scale(1.05);
        }

        &:hover .headline{
            transform: scale(1.1);

            @media screen and (max-width:1024px) {
                transform: scale(1.02);
            }
        }

        &:hover .image{
            transform: scale(0.9);
        }

        &:hover .price {
            transform: scale(1.2);
        }


        @media screen and (max-width:1024px) {
            width: auto;
        }
    }

    .image {
        object-fit: contain;
        max-height: 100%;
        width: 100%;
        border-radius: 20px !important;
        margin-bottom: 20px;
        transition: all linear 200ms;

        @media screen and (max-width:1024px) {
            border-radius: 20px;
        }
    }
    .headline {
        font-size: 25px;
        letter-spacing: 1px;
        margin: 20px 0 15px;
        transition: all linear 200ms;

        @media screen and (max-width:1024px) {
            margin: 15px 0;
            letter-spacing: 2px;
        }

    }
    .description {
        font-size: 18px;
        margin: 0;
        letter-spacing: 1px;
        transition: all linear 200ms;
        text-align: justify;
        line-height: 25px;

        @media screen and (max-width:1024px) {
            margin: 15px 0;
            font-size: 16px;
        }

    }


    .price {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 1.5px;
        margin-bottom: 15px;
        transition: all linear 200ms;
        color: #0101a8;
    }

    .button{
        font-size: 15px;
        margin-bottom: 30px ;

        &:hover {
            box-shadow: 1px 1px 5px 1px rgb(82, 82, 82);
            rotate: none;
            transform: scale(1.1);
        }
    }
    
    a {
        text-decoration: none;
    }

    .feature-row {
        display: flex;
        gap: 30px;

        @media screen and (max-width:1024px) {
            flex-direction: column;
            padding: 0 16px;
        }        
    }
}