.map-container {
    iframe {
        width: 600px;
        height: 400px;
        border-radius: 10px;
        border: 0;
        z-index: 5;
        position: relative;
    }
   @media screen and (max-width:1024px) {
        iframe {
            width: 350px;
            height: 300px;
        }
    }

    @media screen and (max-width:760px) {
        iframe {
            width: 350px;
            height: 300px;
        }
    }
}


  