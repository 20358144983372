.cover-component {
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    padding: 80px 0;
    margin: 0;
    position: relative;
}


.cover-component .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 80px;
}

.cover-component .service-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 50px;
}

.cover-component .service-buttons .service-button-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.cover-component .service-buttons .service-button {
    font-weight: bold;
    border-radius: 15px;
    border: 0.8px solid white;
    justify-content: center;
    padding: 13px 36px;
    display: flex;
    font-size: 18px;
    background-color: #303642c9;
    color: white;
    margin-top: 40px;
    letter-spacing: 1px;
    animation-name: buttonFadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 1.8s;
    opacity: 0;
    z-index: 3;
    transition: all linear 100ms;
}

.cover-component .service-buttons .service-button:hover {
    cursor: pointer;
    background-color: white;
    color: #a33338;
    letter-spacing: 2px;
}

.cover-component::before {
        content: ''; 
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        background: rgba(0, 0, 0, 0.35);
        transition: all linear 100ms;
}

.cover-component:hover::before {
    background: rgba(0, 0, 0, 0.2);
}


.cover-component .cover-component-title {
    text-align: center;
    font-size: 55px;
    letter-spacing: 3px;
    margin: 0;
    animation-name: titleSlideIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 100ms;
    opacity: 0;
    color: white;
    text-shadow: 2px 2px 2px black;
    z-index: 3;
}

.cover-component .cover-component-title span {
    color: white;
}

.cover-component .textbox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;
    background-color: rgba(0, 0, 0, 0.54);
    padding: 80px 80px; 
    border-radius: 15px; 
    background-size: cover;
    background-position: center;
    box-shadow: 1px 1px 15px 5px white;
    z-index: 3;
}

.cover-component .textbox-centered {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;
}

.cover-component .responsive-logo {
    width: 100%;
    z-index: 3;
}

@keyframes titleSlideIn {
    0% {
        opacity: 0;
        transform: translateX(-500px);
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

.cover-component .cover-component-headline {
    text-align: center;
    font-size: 38px;
    letter-spacing: 2px;
    color: white;
    animation-name: headlineSlideIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 500ms;
    opacity: 0;
    text-shadow: 2px 2px 2px black;
    z-index: 3;
}

@keyframes headlineSlideIn {
    0% {
        opacity: 0;
        transform: translateX(500px);
    }
    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

.cover-component a {
    text-decoration: none;
}

.cover-component-button {
    font-weight: bold;
    border-radius: 25px;
    justify-content: center;
    padding: 18px 46px;
    display: flex;
    font-size: 23px;
    background-color:#db101a;
    color: white;
    border: none ;
    margin-top: 40px;
    letter-spacing: 2px;
    animation-name: buttonFadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 800ms;
    opacity: 0;
    z-index: 3;
}

@keyframes buttonFadeIn {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1)
    }
}

.cover-component-button:hover {
    box-shadow: 0px 1px 10px 0px white;
    rotate: -2deg;
    transform: scale(1.1);
    transition: all 300ms;
    color: white;
    cursor: pointer;
    background-color: #ff323c;
}

@media screen and (max-width: 1124px) {

    .cover-component {
        background-size: cover;
        background-position: center;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cover-component .container {
        padding: 90px 15px;
    }

    .cover-component .service-buttons {
        flex-direction: column;
        gap: 0;
    }

    .cover-component .service-buttons .service-button-row {
        flex-direction: column;
        gap: 0;
    }

    .cover-component .textbox-centered {
        margin-top: 0px;
    }

    .cover-component .cover-component-title {
        font-size: 40px;
        margin-top: 10px;
        margin-bottom: 20px ;
        line-height: 43px;
    }

    .cover-component .cover-component-headline {
        font-size: 18px;
    }

    .cover-component .textbox {
        padding: 0;
    }

    .cover-component .textbox .responsive-logo {
        display: block;
        height: 270px;
        width: 300px;
    }

    .cover-component-button {
        border-radius: 50px;
        margin: 20px;
    }

}

@media screen and (max-width: 768px) {

    .cover-component {
        background-size: cover;
        background-position: 800px;
    }

    .cover-component .container {
        padding: 30px 15px;
    }

    .cover-component .textbox-centered {
        margin-top: 60px;
    }

    .cover-component .service-buttons {
        flex-direction: column;
        gap: 0;
        margin-top: 35px;
        align-items: center;
        justify-content: center;
    }

    .cover-component .service-buttons .service-button-row {
        flex-direction: column;
        gap: 0;
    }

    .cover-component .cover-component-title {
        font-size: 30px;
        letter-spacing: normal;
    }

    .cover-component .cover-component-headline {
        font-size: 18px;
    }

    .cover-component .textbox {
        padding: 0;
        background: none;
    }

    .cover-component .textbox .responsive-logo {
        display: block;
        height: 270px;
        width: 300px;
    }

    .cover-component-button {
        border-radius: 50px;
        margin: 20px;
        padding: 14px 38px;
        font-size: 21px;
    }

}
