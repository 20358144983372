.footer {
    background: rgb(33, 36, 41);
    color: white;
    padding: 30px 80px;

    @media screen and (max-width:1024px) {
        padding: 0;
        margin-top: auto;
    }

    .information-links {
        list-style-type: none;

        @media screen and (max-width: 1024px) {
           padding: 0;
        }
    }

    .information-links li {
        margin: 5px 0 10px 0;
    }

    .information-links li a {
        color: white;
        font-size: 20px;
        text-transform: uppercase;
        text-decoration: none;
    }

    .information-links li a:hover {
        color: #e4c46a;
    }


    .footer-logo {
        width: 130px;
        height: 120px;
        padding: 0px;
        margin: 0;
    }

    .footer-contact-information  {
        font-size: 20px;
        display: flex;
        
        @media screen and (max-width:1024px) {
            flex-direction: column;
        }
    }


    .footer-contact-information a {
        color: #e4c46a;
        transition: all linear 150ms;

        &:hover {
            color: white;
        }

        @media screen and (max-width:1024px) {
            color: white;
        }
    }

    .footer-contact-information span {
        display: inline-flex;
        width: 150px;
        color: #e4c46a;
    }

    .footer-content {
        display: flex;
        justify-content: center;
        gap: 50px;

        @media screen and (max-width:1024px) {
            flex-direction: column;
            display: flex;
            gap: 30px;
            align-items: center;
            padding: 30px 0px;
        }
    }

    .footer-block {
        width: 20%;

        .social-media-links {   
            display: flex;
            gap: 14px;
            margin-top: 30px;
            justify-content: center;

            li {
                list-style-type:none ;

                &:last-child {
                    .social-icon {
                        height: 40px;
                        width: 40px;
                    }
                }

                &:nth-child(3){
                    .social-icon {
                        height: 40px;
                        width: 40px;
                        object-fit: cover;
                    }
                }

                .social-icon {
                    width: 40px;
                    height: 40px;
                    transition: all linear 200ms;
                    
                    &:hover {
                        transform: scale(1.1);
                    }
                }
                
            }
        }

        @media screen and (max-width: 1024px) {
            width: 50%;
        }
    }

    .footer-block:last-child {
        width: 40%;

        @media screen and (max-width:1024px) {
            width: 50%;
        }
    }

    .footer-block .headline {
        color: #e4c46a;
        letter-spacing: 1px;
    }

    .logo {
        width: 20%;
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1024px) {
            width: auto;
        }
    }
}