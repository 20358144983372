.notFound {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: top;
    padding: 0;

    &::before {
        content: ''; 
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        background: rgba(0, 0, 0, 0.45);
        transition: all linear 100ms;
    }

    .container {
        padding: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media screen and (max-width: 1024px) {
            padding: 28px;
        }

        * {
            z-index: 2;
        }

        .title {
            font-size: 50px;
            letter-spacing: 1px;
            color: white;
            text-align: center;

            @media screen and (max-width: 1024px) {
                font-size: 28px;
            }
        }

        .description {
            font-size: 30px;
            color: white;
            font-weight: 500;
            text-align: center;

            @media screen and (max-width: 1024px) {
                font-size: 22px;
            }
        }

        .button {
            margin-top: 30px;

            &:hover {
                box-shadow: none;
                transition: all 200ms linear;
                rotate: none;
            }
        }
    }

    
}