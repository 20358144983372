.contact-screen {
    background-size: cover;
    background-position: left;
    width: 100%;
    min-height: 100vh;
    position: relative;
}

.contact-screen::before {
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.1);
}

.contact-screen .container {
    padding: 80px 60px;
}

.contact-screen .title {
    margin: 40px 40px 0 40px;
    padding: 0;
    font-size: 65px;
    display: flex;
    justify-content: center;
    text-align: center;
    color: white;
    letter-spacing: 1px;
    animation-name: titleFadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    position: relative;
    animation-delay: 0.2s;
    opacity: 0;
    transition: all linear 200ms;
    text-shadow: 2px 2px 2px rgb(0, 0, 0);
}

@keyframes titleFadeIn {
    0% {
        opacity: 0;
        top: -120px;
    }
    100% {
        opacity: 1;
        top: 0;
    }
}

.contact-screen .contact-information-row {
    display: flex;  
    justify-content: center;
    align-items: center;
}

@keyframes textFadeIn {
    0% {
        opacity: 0;
        left: 120px;
    }
    100% {
        opacity: 1;
        left: 0;
    }
}

.contact-screen .contact-information-box {
    margin: 50px;
    padding: 20px;
    min-height: 330px;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    display: flex;
    color: white;
    border-radius: 10px;
    animation-name: boxFadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 0.2s;
    opacity: 0;
    transition: all linear 200ms;
    transform: scale(1.05);
    box-shadow: 1px 1px 10px 5px rgb(255, 255, 255);
    position: relative;
    background-size: cover;
    background-position: center;
}

.contact-information-box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    border-radius: 10px;
}

.social-media-links {   
    display: flex;
    gap: 14px;
    padding: 0;
    margin-top: 30px;
}
.social-media-links li {
    list-style-type:none ;
}

.social-media-links li:hover {
    z-index: 3;
    position: relative;
    cursor: pointer;
}

.social-media-links .social-icon {
    width: 40px;
    height: 40px;
    position: relative;
    cursor: pointer;
    z-index: 3;
    transition: all linear 200ms;
}

.social-media-links .social-icon:hover {
    transform: scale(1.1);
}

@keyframes boxFadeIn {
    0% {
        opacity: 0;
        right: 120px;
    }
    100% {
        opacity: 1;
        right: 0;
    }
}

.contact-screen .contact-information-box:hover {
    transition: all 0.8s;
}

.contact-screen .contact-information-box p {
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 1px;
    text-shadow: 1px 1px 30px 1px white;
    color: white;
    margin: 20px;
    transition: all linear 200ms;
    font-weight: bold;
}

.contact-screen .contact-information-box .headline {
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 2px;
    transition: all linear 100ms;
    position: relative;
    z-index: 2;
}


.contact-screen .contact-information-box .headline:hover {
    transform: scale(1.08);
}


.contact-screen .contact-information-box a {
    color: white;
    letter-spacing: 1px;
    font-weight: normal;
    transition: all linear 150ms;
}

.contact-screen .contact-information-box a:hover {
    color: rgb(185, 0, 0);
}


.contact-screen .contact-information-box p:hover {
    color: rgb(255, 255, 255);
}


.headline, .contact-information {
    position: relative;
    z-index: 2; 
}


@media screen and (max-width:1024px) {
    
    .contact-screen .container {
        padding: 80px 21px;
    }

    .contact-screen .title {
        margin: 40px;
    }

    .contact-screen .contact-information-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .contact-screen .contact-information-box {
        margin:0 0 30px 0;
        width: auto;
        min-height: 0;
        padding: 18px;
    }

    .contact-screen .contact-information-box .headline {
        font-size: 35px;
        letter-spacing: 1px;
        margin: 11px 0 20px 0;
    }

    .contact-screen .contact-information-box p {
        margin: 15px 0;
        font-size: 16px;
        font-weight: 600;
    }

    .contact-screen .contact-information-row .contact-screen-description {
        width: 100%;
    }

    .contact-screen .contact-information-row .contact-screen-description .headline {
        margin: 0;
        font-size: 22px;
    }

}